<template>
  <main class="view view--settings">
    <section class="header">
      <div class="header__left">
        <router-link
          :to="{ name: 'settings' }"
          class="btn btn-prev"
        ></router-link>
      </div>

      <div class="header__center">
        <p class="title">{{ $t("Notifications") }}</p>
      </div>

      <div class="header__right"></div>
    </section>

    <section class="settings">
      <ListItem
        v-for="(item, index) in listitems"
        v-model="item.value"
        :key="index"
        :label="item.label"
        :config="item.config"
        :icon="item.icon"
        :type="item.type"
        :clickHandler="item.onClick"
      ></ListItem>
    </section>
  </main>
</template>

<script>
import ListItem from "./../../components/ListItem.vue";

export default {
  components: {
    ListItem,
  },
  data: function () {
    return {
      listitems: [
        {
          label: this.$t("Received notifications"),
          onClick: this.checkNotifications,
          type: "switch",
          config: {
            theme: "prixo",
            color: "primary",
            type_bold: true,
          },
          value: false,
        },
      ],
    };
  },
  methods: {
    checkNotifications: function (value) {
      var self = this;
      if (value) {
        // User would like to receive notifications
        // eslint-disable-next-line no-unused-vars
        this.$webpush.subscribe().catch((e) => {
          if (Notification.permission === "denied") {
            alert(
              this.$t(
                "Please accept notifications in your browser to receive them."
              )
            );
            self.listitems[0].value = false;
          } else {
            alert(this.$t("Your device does not support notifications."));
            self.listitems[0].value = false;
          }
        });
      } else {
        // Unsubscribe user from notifications
      }
    },
  },
};
</script>
